import React from "react"
import styled from "styled-components"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"

const Container = styled.div`
  max-width: 68.75rem;
  margin: 0 auto;
  h2 {
    font: ${({ theme }) => theme.headline.headlineSmHighlight};
    padding-bottom: 1rem;
  }
  h3 {
    font: ${({ theme }) => theme.text.textXl};
  }
  p {
    font: ${({ theme }) => theme.text.textBase};
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    .informations,
    .data {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .informations {
      max-width: 45.625rem;
    }
    .data {
      max-width: 11.25rem;
      margin-right: 2rem;

      .size-info {
        font-size: 0.875rem;
      }
    }
  }

  .padding {
    padding-top: 8.875rem;
    padding-bottom: 5.05rem;
  }

  button {
    padding: 0.5rem 1rem;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 11.75rem;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 5rem;
    padding-bottom: 4.2rem;
    .save-button {
      background: #2d2d2d;
      color: #ffffff;
    }
  }
`

const Verificacoes = () => {
  return (
    <>
      <Header />
      <Container>
        <h2>Verificações</h2>
        <div className="wrapper">
          <div className="informations">
            <h3>Foto de verificação</h3>
            <p>
              Para garantir sua segurança e das pessoas usuárias, precisamos que
              você segure sua carteira profissional ao lado do seu rosto e tire
              uma foto com um fundo simples. Agora é só enviar por meio do botão
              ao lado.
            </p>
          </div>
          <div className="data">
            <button>Carregar Imagem</button>
            <p className="size-info">Tamanho máximo:</p>
          </div>
        </div>
        <div className="wrapper padding">
          <div className="informations">
            <h3>Carta de entrada (Opcional)</h3>
            <p>
              Chegamos ao último passo! Para nos conhecermos melhor, conte pra
              nós os motivos que fazem você querer atender a comunidade
              LGBTQIAPN+. Agora é só enviar por meio do botão ao lado.
            </p>
          </div>
          <div className="data">
            <button>Envie sua carta</button>
            <p className="size-info">Tamanho máximo:</p>
          </div>
        </div>
        <div className="buttons-container">
          <button>Voltar</button>
          <button className="save-button">Salvar</button>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Verificacoes
